import React from 'react';
import TrailersPage from "../pages/trailers.page";
import PartnersPage from "../pages/partners.page";
import NewsPage from "../pages/news.page";
import ContactsPage from "../pages/contacts.page";
import TextPage from "../pages/text.page";

const SubTemplate = React.memo(({lang, subType, datas, langs, menu, sub}) => {

    const [page, setPage] = React.useState({});

    React.useEffect(() => {
        if (subType && subType !== 'text' && menu) {
            setPage(() => menu.filter((item) => item.types === subType)[0]);
        }
    }, [menu, subType]);

    return (
        <>
            {
                subType === 'types' && <TrailersPage page={page} datas={datas} subType={subType} langs={langs} lang={lang} menu={menu} />
            }
            {
                subType === 'partners' && <PartnersPage page={page} datas={datas} subType={subType} langs={langs} lang={lang} menu={menu} subUrl={sub} />
            }
            {
                subType === 'news' && <NewsPage page={page} datas={datas} subType={subType} langs={langs} lang={lang} menu={menu}/>
            }
            {
                subType === 'contacts' && <ContactsPage page={page} datas={datas} langs={langs} lang={lang} menu={menu} subType={subType} />
            }
            {
                subType === 'text' && <TextPage page={page} datas={datas} langs={langs} lang={lang} menu={menu} subType={subType} sub={sub} />
            }
        </>
    );
});

export default SubTemplate;