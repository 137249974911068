import React from 'react';
import classes from './error.module.sass';
import Button from "../../ui/button/button";
import Container from "../../ui/container/container";
import Space from "../../ui/space/space";
import Centered from "../../ui/centered/centered";
import T from "../t/t";

const ErrorComponent = ({datas}) => {
    return (
        <div className={classes.error}>
            <Container>
                <Space space={8}/>
                <p>
                    <span className={classes.error_number}>
                        <strong>4</strong>0<strong>4</strong>
                    </span>
                </p>
                <Space space={2}/>
                <h1>
                    <T datas={datas}>Страница не найдера</T>
                </h1>
                <Space space={1}/>
                <p className={classes.error_text}>
                    <T datas={datas}>Упс, но такой страницы не существует</T>
                </p>
                <Space space={4}/>
                <Centered>
                    <Button to={'/'} variant={'borderWithIcon'} rotateIcon={90}><T datas={datas}>Перейти на главную</T></Button>
                </Centered>
                <Space space={8}/>
            </Container>
        </div>
    );
};

export default ErrorComponent;