import React from 'react';
import Container from "../../ui/container/container";
import ColumnsSmall from "../../ui/columns/columnsSmall";
import classes from './news.module.sass';
import Space from "../../ui/space/space";
import Share from "../../ui/share/share";
import Centered from "../../ui/centered/centered";
import Back from "../../ui/back/back";
import {useLocation} from "react-router-dom";
import moment from "moment";
import parse from "html-react-parser";
import T from "../t/t";
import CustomImg from "../../ui/customImg/customImg";

const NewsOneComponent = ({datas, sub}) => {
    const location = useLocation();

    const [page, setPage] = React.useState({});

    React.useEffect(() => {
        const page = Object.values(datas[sub]).filter(item => item.url === location.pathname.split('/').pop())[0]
        setPage(() => page)
    }, [datas, location.pathname, sub]);

    return (
        <>
            <Container>
                <ColumnsSmall>
                    <Back to={'/' + location.pathname.split('/').at(-2)} title={<T datas={datas}>Back to all news</T>}/>
                    <h1>{page && page.topic}</h1>
                    <p className={classes.date}><span>{page && moment(Date(page.add_utime)).format('DD MMMM, YYYY')}</span></p>
                    <CustomImg
                        className={'image'}
                        aria-hidden={true}
                        fetchPriority={'high'}
                        width={690}
                        height={386}
                        src={page && process.env.REACT_APP_STATIC_URL + page.img}
                        alt={page && page.topic}
                    />
                    {
                        page && page.text && parse(page.text)
                    }
                    <Space space={4}/>
                    <hr/>
                    <Space space={4}/>
                    <h4 style={{textAlign: 'center'}}><T datas={datas}>Share the news</T></h4>
                    <Space space={2}/>
                    <Centered>
                        <Share
                            items={Object.values(datas['shared'])}
                        />
                    </Centered>
                    <Space space={8}/>
                </ColumnsSmall>
            </Container>
        </>
    );
};

export default NewsOneComponent;