import React from 'react';
import Space from "../ui/space/space";
import Top from "../chanks/top";
import Footer from "../chanks/footer";
import Seo from "../components/seo/seo";
import NewsOneComponent from "../components/news/newsOne.component";


const NewsOnePage = ({page, datas, lang, sub, menu, langs, subUrl}) => {

    return (
        <>
            <Seo page={page}/>
            <Top lang={lang} menu={menu} langs={langs} subUrl={subUrl} datas={datas} subType={sub}/>
            <Space space={8}/>
            <NewsOneComponent datas={datas} lang={lang} sub={sub} />
            <Footer lang={lang} menu={menu} datas={datas}/>
        </>
    );
};

export default NewsOnePage;