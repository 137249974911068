import React from 'react';
import {Link} from "react-router-dom";

const CustomLink = ({className, to, title, target, children, width, height, style}) => {
    return (
        <Link
            style={style}
            className={className}
            width={width}
            height={height}
            target={target ? target : '_self'}
            to={localStorage.getItem('lang') && target !== '_blank' ? '/' + localStorage.getItem('lang') + to : to}
            alt={title}
        >{children}</Link>
    );
};

export default CustomLink;