import React from 'react';
import classes from './partnerBanner.module.sass';
import Space from "../../../ui/space/space";
import Container from "../../../ui/container/container";
import Button from "../../../ui/button/button";
import parse from "html-react-parser";
import T from "../../t/t";
import CustomImg from "../../../ui/customImg/customImg";

const PartnerBannerComponent = ({page, datas, menu}) => {

    console.log('menu', menu.filter(item => item.types === 'contacts')[0]?.url)

    return (
        <div className={classes.partnerBannerWrapper}>
            <Space space={2}/>
            <Container>
                <div className={classes.partnerBanner}>
                    <div className={classes.left}>
                        <h1>{page.topic}</h1>
                        {
                            page.text && parse(page.text)
                        }
                        <Button to={`/${menu.filter(item => item.types === 'contacts')[0]?.url}`} variant={'full'}><T datas={datas}>Find Out How</T></Button>
                        <Space space={8}/>
                    </div>
                    <div className={classes.right}>
                        <CustomImg src={process.env.REACT_APP_STATIC_URL + page.partner_img} alt="Become a Wheelie Partner"/>
                    </div>
                </div>
            </Container>
        </div>
);
};

export default PartnerBannerComponent;