import React from 'react';
import Space from "../ui/space/space";
import Top from "../chanks/top";
import Footer from "../chanks/footer";
import Seo from "../components/seo/seo";
import NewsComponent from "../components/news/news.component";


const NewsPage = ({page, langs, lang, menu, datas, subType}) => {

    return (
        <>
            <Seo page={page}/>
            <Top menu={menu} langs={langs} lang={lang} datas={datas} subType={subType}/>
            <Space space={8}/>
            <NewsComponent datas={datas} page={page} data={datas && Object.values(datas[subType])} />
            <Space space={8}/>
            <Footer lang={lang} menu={menu} datas={datas}/>
        </>
    );
};

export default NewsPage;