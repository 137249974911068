import React from 'react';

const CustomImg = ({width, height, src, alt, style, onClick, className}) => {
    return (
        <picture className={className}>
            <source className={className} width={width} height={height} srcSet={src} type="image/webp"/>
            <amp-img className={className} width={width} height={height} src={src} style={style}>
                <img className={className} onClick={onClick} style={style} loading={'lazy'} width={width} height={height} src={src} alt={alt}/>
            </amp-img>
        </picture>
    );
};

export default CustomImg;