import React from 'react';
import Space from "../../ui/space/space";
import CardsBlock from "../../ui/card/cardsBlock";
import Card from "../../ui/card/card";
import Centered from "../../ui/centered/centered";
import Button from "../../ui/button/button";
import Container from "../../ui/container/container";
import T from "../t/t";
import classes from "../partners/partnersSlider.module.sass";
import parse from "html-react-parser";

const TrailersComponent = ({page, count, data, subUrl, datas}) => {

    const [typesTrailersFiltered, setTypesTrailersFiltered] = React.useState(data.sort((a, b) => Number(a.position) - Number(b.position)));
    const [hiddenBtn, setHiddenBtn] = React.useState(false);

    React.useEffect(() => {
        if (subUrl) {
            setTypesTrailersFiltered(() => data.slice(0, 6));
        } else {
            setTypesTrailersFiltered(() => data)
        }
    }, [data]);

    // const loadTrailers = (count) => {
    //     // setTypesTrailersFiltered(i => [...i, ...data.slice(typesTrailersFiltered.length, typesTrailersFiltered.length + count)])
    //     // if (typesTrailersFiltered.length + count >= data.length) {
    //     //     setHiddenBtn(() => true)
    //     // }
    //     setTypesTrailersFiltered(() => data)
    // }
    //
    // React.useEffect(() => {
    //     loadTrailers()
    // }, [])

    return (
        <Container>
            {
                page.types === 'home' ?
                    <h2><T datas={datas}>Наши прицепы</T> <span>({count})</span></h2>
                    :
                    <div className={classes.partnetSlidet__title}>
                        <h1>{page.topic} <span style={{
                            marginTop: '-0.5rem',
                            position: 'absolute',
                            paddingLeft: '1rem',
                            fontSize: '1.8rem',
                            color: '#706E81',
                            fontFamily: 'InterSemiBold, sans-serif',
                        }}>({count})</span></h1>
                        <div className={classes.partnetSlidet__subtitle}>
                            {page.text && parse(page.text)}
                        </div>
                    </div>
            }
            <Space space={2}/>
            <CardsBlock>
                {
                    typesTrailersFiltered.sort((a, b) => a.position - b.position).map(item => {
                        return (
                            <Card
                                key={item.id}
                                to={subUrl ? '/' + subUrl + '/' + item.url : '/' + page.url + '/' + item.url}
                                img={process.env.REACT_APP_STATIC_URL + `${item.img.split('.')[0] + '_320x240' + '.' + item.img.split('.').at(-1)}`}
                                title={item.title}
                                alt={item.title}
                                data={
                                    [{
                                        name: <T datas={datas}>Load capacity</T>,
                                        data: `${item.brutto - item.netto} kg`
                                    }, {name: <T datas={datas}>Cargo space dimensions</T>, data: item.sizes}]
                                }
                                price={item.price_1hour}
                                textBtn={<T datas={datas}>More</T>}
                            />
                        )
                    })
                }
            </CardsBlock>
            {
                !hiddenBtn
                &&
                <>
                    <Space space={4}/>
                    <Centered>
                        {
                            subUrl
                                &&
                                <Button to={'/' + subUrl} rotateIcon={-90} variant={'borderWithIcon'}><T
                                    datas={datas}>Смотреть все</T></Button>
                                // :
                                // <Button variant={'borderWithIconBTN'} onClick={() => loadTrailers(6)}><T
                                //     datas={datas}>Загрузить еще</T></Button>
                        }
                    </Centered>
                </>
            }
        </Container>
);
};

export default TrailersComponent;