import React from 'react';
import TrailersComponent from "../components/trailers/trailers.component";


const Trailers = ({page, data, subUrl, datas}) => {

    return (
        <TrailersComponent
            datas={datas}
            page={page}
            count={data.length}
            data={data}
            subUrl={subUrl}
        />
    );
};

export default Trailers;