import React from 'react';
import Space from "../ui/space/space";
import Top from "../chanks/top";
import Footer from "../chanks/footer";
import Trailers from "../chanks/trailers";
import Seo from "../components/seo/seo";

const TrailersPage = ({page, langs, lang, menu, datas, subType}) => {

    return (
        <>
            <Seo page={page}/>
            <Top menu={menu} langs={langs} lang={lang} datas={datas} subType={subType}/>
            <Space space={8}/>
            <Trailers page={page} data={datas && Object.values(datas[subType])} datas={datas} />
            <Space space={8}/>
            <Footer lang={lang} menu={menu} datas={datas}/>
        </>
    );
};


export default TrailersPage;