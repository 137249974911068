import React from 'react';
import {useLocation} from "react-router-dom";
import SiteMethods from "./methods/site.methods";
import SubTemplate from "./templates/sub.template";
import SubSubTemplate from "./templates/subSub.template";
import HomeTemplate from "./templates/home.template";
import Loader from "./ui/loader/loader";
import Error from "./Error";

const App = () => {
    const [parseLocation, setParseLocation] = React.useState([]);
    const [langs, setLangs] = React.useState([]);
    const [currentLang, setCurrentLang] = React.useState("");
    const [allLangDataObj, setAllLangDataObj] = React.useState({});

    const [sub, setSub] = React.useState("");
    const [subType, setSubType] = React.useState("");
    const [subSub, setSubSub] = React.useState("");

    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(true);
    const [loadingMain, setLoadingMain] = React.useState(false);

    const [component, setComponent] = React.useState(null);

    const location = useLocation();

    const normalizeString = (str) => {
        return str.toLowerCase().trim();
    }

    React.useEffect(() => {
        if (location.pathname === '/' || location.pathname === `/${currentLang}/` || location.pathname === `/${currentLang}`) {
            setSub(() => {});
            setSubSub(() => {});
        }
        setLoadingMain(() => true);
        setTimeout(() => {
            setLoadingMain(() => false);
        }, 100);
    }, [location.pathname, currentLang]);

    /***
     * Загрузка всех языков
     ***/
    const loadLangs = async () => {
        const [
            langs
        ] = await Promise.allSettled([
            SiteMethods.getLangs(),
        ])
        if (langs.status === 'fulfilled') {
            if (langs.value.result === 'ok') {
                setLangs(() => Object.values(langs.value.results));
            }
        }
    }

    React.useEffect(() => {
        loadLangs().then();
    }, []);

    /***
     * Конец загрузки всех языков
     ***/

    /***
     * Загрузка всех данных
     ***/
    const loadAllData = async (lang) => {
        const [
            main,
            config,
            menu,
            types,
            trailers,
            partners,
            news,
            shared,
            social,
            faq,
            staticData,
            parking,
        ] = await Promise.allSettled([
            SiteMethods.getMain(lang),
            SiteMethods.getConfig(lang),
            SiteMethods.getMenu(lang),
            SiteMethods.getTypeTrailers(lang),
            SiteMethods.getTrailers(lang),
            SiteMethods.getPartners(lang),
            SiteMethods.getNews(lang),
            SiteMethods.getShared(lang),
            SiteMethods.getSocial(lang),
            SiteMethods.getFaq(lang),
            SiteMethods.getStatic(lang, 'app'),
            SiteMethods.getParking(lang),
        ]);
        if (
            main.status === 'fulfilled' &&
            config.status === 'fulfilled' &&
            menu.status === 'fulfilled' &&
            types.status === 'fulfilled' &&
            trailers.status === 'fulfilled' &&
            partners.status === 'fulfilled' &&
            news.status === 'fulfilled' &&
            shared.status === 'fulfilled' &&
            social.status === 'fulfilled' &&
            faq.status === 'fulfilled' &&
            staticData.status === 'fulfilled' &&
            parking.status === 'fulfilled'
        ) {
            if (
                main.value.result === 'ok' &&
                config.value.result === 'ok' &&
                menu.value.result === 'ok' &&
                types.value.result === 'ok' &&
                trailers.value.result === 'ok' &&
                partners.value.result === 'ok' &&
                news.value.result === 'ok' &&
                shared.value.result === 'ok' &&
                social.value.result === 'ok' &&
                faq.value.result === 'ok' &&
                staticData.value.result === 'ok' &&
                parking.value.result === 'ok'
            ) {
                const all = {}
                all.main = main.value.results
                all.config = config.value.results
                all.menu = menu.value.results
                all.terms = menu.value.results.terms
                all.policy = menu.value.results.policy
                all.types = types.value.results
                all.trailers = trailers.value.results
                all.partners = partners.value.results
                all.news = news.value.results
                all.shared = shared.value.results
                all.social = social.value.results
                all.faq = faq.value.results
                all.staticData = staticData.value.results
                all.parking = parking.value.results
                return all;
            } else {
                setError(() => 'Ошибка загрузки данных');
            }
        }
    }

    /***
     * Загрузка всех данных от всех языков
     ***/
    React.useEffect(() => {
        if (langs.length > 0 && location.pathname) {
            const allData = {};
            (async () => {
                for (const item of langs) {
                    allData[item.code] = await loadAllData(item.code);
                }
                setAllLangDataObj(() => allData);
                setLoading(() => false);
            })();
        }
        setComponent(() => null)
    }, [langs, location.pathname]);
    /***
     * Конец загрузки всех данных от всех языков
     ***/
    /***
     * Конец загрузкивсех данных
     ***/

    /***
     * Парсинг поисковой строки
     ***/
    React.useEffect(() => {
        setParseLocation(() => location.pathname.split('/'));
    }, [location.pathname]);
    /***
     * Конец парсинга поисковой строки
     ***/

    /***
    /* Определение языка
    /***/
    React.useEffect(() => {
        if (parseLocation.length > 0 && langs.length > 0 && Object.keys(allLangDataObj).length > 0) {
            let curLang;
            if (parseLocation.at(1).length === 2) {
                const selectLang = langs.filter(item => item.code === parseLocation.at(1));
                if (selectLang.length > 0) {
                    setCurrentLang(() => selectLang[0].code);
                    localStorage.setItem('lang', selectLang[0].code);
                    localStorage.removeItem('defLang');
                    curLang = selectLang[0].code;
                } else {
                    if (localStorage.getItem('lang')) {
                        setCurrentLang(() => localStorage.getItem('lang'));
                        curLang = localStorage.getItem('lang');
                    } else {
                        const defaultLang = langs.filter(item => item.default);
                        if (defaultLang.length > 0) {
                            setCurrentLang(() => defaultLang[0].code);
                            localStorage.setItem('defLang', defaultLang[0].code);
                            curLang = defaultLang[0].code;
                        }
                    }
                    setComponent(
                        () =>
                            <Error
                                lang={curLang}
                                langs={langs}
                                datas={allLangDataObj[curLang]}
                                menu={Object.values(allLangDataObj[curLang].main)}
                            />
                    );
                }
            }
            if (parseLocation.at(1).length > 2 || parseLocation.at(1).length === 0) {
                const defaultLang = langs.filter(item => item.default);
                if (defaultLang.length > 0) {
                    setCurrentLang(() => defaultLang[0].code);
                    curLang = defaultLang[0].code;
                }
                localStorage.removeItem('lang');
                localStorage.setItem('defLang', defaultLang[0].code);
            }
            localStorage.setItem('langs', JSON.stringify(langs));
            localStorage.setItem('datas', JSON.stringify(allLangDataObj[curLang]));
            localStorage.setItem('menu', JSON.stringify(Object.values(allLangDataObj[curLang].main)));
        }
    }, [parseLocation, langs, allLangDataObj]);
    /***
    /* Конец определения языка
    /***/

    /***
    /* Определение передан ли sub
    /***/
    React.useEffect(() => {
        if (parseLocation.length > 0 && currentLang) {
            if (
                parseLocation.at(1).length === 2 &&
                parseLocation.at(2) &&
                parseLocation.at(2).length > 0 &&
                (!parseLocation.at(3) || parseLocation.at(3).length === 0)
            ) {
                setSubSub(() => {});
                if (Object.keys(allLangDataObj).length > 0) {
                    const searchedSub = Object.values(allLangDataObj[currentLang].main).filter(
                        item => item &&
                            (
                                normalizeString(item.url) === normalizeString(parseLocation[2])
                            )
                    );
                    let searchSubTerms = {};
                    let searchSubPolicy = {};

                    if (normalizeString(allLangDataObj[currentLang].terms.code) === normalizeString(parseLocation[2])) {
                        searchSubTerms.url = normalizeString(allLangDataObj[currentLang].terms.link);
                        searchSubTerms.types = normalizeString(allLangDataObj[currentLang].terms.type);
                    }
                    if (normalizeString(allLangDataObj[currentLang].policy.code) === normalizeString(parseLocation[2])) {
                        searchSubPolicy.url = normalizeString(allLangDataObj[currentLang].policy.link);
                        searchSubPolicy.types = normalizeString(allLangDataObj[currentLang].policy.type);
                    }

                    if (searchedSub.length > 0) {
                        setSub(() => searchedSub[0].url);
                        setSubType(() => searchedSub[0].types);
                    } else if (Object.keys(searchSubTerms).length > 0) {
                        setSub(() => searchSubTerms.url);
                        setSubType(() => searchSubTerms.types);
                    } else if (Object.keys(searchSubPolicy).length > 0) {
                        setSub(() => searchSubPolicy.url);
                        setSubType(() => searchSubPolicy.types);
                    } else {
                        setComponent(() => <Error
                            lang={currentLang}
                            langs={langs}
                            datas={allLangDataObj[currentLang]}
                            menu={Object.values(allLangDataObj[currentLang].main)}
                        />);
                    }
                }
            } else if (
                parseLocation.at(1).length > 2 &&
                !parseLocation.at(2)
            ) {
                setSubSub(() => {});
                if (Object.keys(allLangDataObj).length > 0) {
                    const searchedSub = Object.values(allLangDataObj[currentLang].main).filter(
                        item => item &&
                            (
                                normalizeString(item.url) === normalizeString(parseLocation[1])
                            )
                    );

                    let searchSubTerms = {};
                    let searchSubPolicy = {};

                    if (normalizeString(allLangDataObj[currentLang].terms.code) === normalizeString(parseLocation[1])) {
                        searchSubTerms.url = normalizeString(allLangDataObj[currentLang].terms.link);
                        searchSubTerms.types = normalizeString(allLangDataObj[currentLang].terms.type);
                    }
                    if (normalizeString(allLangDataObj[currentLang].policy.code) === normalizeString(parseLocation[1])) {
                        searchSubPolicy.url = normalizeString(allLangDataObj[currentLang].policy.link);
                        searchSubPolicy.types = normalizeString(allLangDataObj[currentLang].policy.type);
                    }
                    if (searchedSub.length > 0) {
                        setSub(() => searchedSub[0].url);
                        setSubType(() => searchedSub[0].types);
                    } else if (Object.keys(searchSubTerms).length > 0) {
                        setSub(() => searchSubTerms.url);
                        setSubType(() => searchSubTerms.types);
                    } else if (Object.keys(searchSubPolicy).length > 0) {
                        setSub(() => searchSubPolicy.url);
                        setSubType(() => searchSubPolicy.types);
                    } else {
                        setComponent(() => <Error
                            lang={currentLang}
                            langs={langs}
                            datas={allLangDataObj[currentLang]}
                            menu={Object.values(allLangDataObj[currentLang].main)}
                        />);
                    }
                }
            }
        }
    }, [parseLocation, currentLang, allLangDataObj, langs]);
    /***
    /* Конец определения передан ли sub
    /***/

    /***
    /* Определение передан ли sub в sub
    /***/
    React.useEffect(() => {
        if (parseLocation.length > 0 && currentLang) {
            if (
                parseLocation.at(1).length === 2 &&
                parseLocation.at(2) &&
                parseLocation.at(2).length > 0 &&
                parseLocation.at(3) &&
                parseLocation.at(3).length > 0
            ) {
                const searchedSubSub = Object.values(allLangDataObj[currentLang].main).filter(
                    item => item &&
                        (
                            normalizeString(item.url) === normalizeString(parseLocation[2])
                        )
                );
                if (searchedSubSub.length > 0) {
                    if (Object.keys(allLangDataObj).length > 0) {
                        const searchedTypes = Object.values(allLangDataObj[currentLang][searchedSubSub[0].types]);
                        if (searchedTypes.length > 0) {
                            const types = searchedTypes.filter(
                                item => item && (
                                    (parseLocation[3] && item.url === normalizeString(parseLocation[3]))
                                )
                            );
                            if (types.length > 0) {
                                setSub(() => parseLocation[2]);
                                setSubSub(() => types[0].url);
                                setSubType(() => searchedSubSub[0].types);
                            }
                        }
                    } else {
                        setComponent(() =>
                            <Error
                                lang={currentLang}
                                langs={langs}
                                datas={allLangDataObj[currentLang]}
                                menu={Object.values(allLangDataObj[currentLang].main)}
                            />
                        );
                    }
                }
            } else if (
                parseLocation.at(1).length > 2 &&
                parseLocation.at(2) &&
                parseLocation.at(2).length > 0 &&
                !parseLocation.at(3)
            ) {
                const searchedSubSub = Object.values(allLangDataObj[currentLang].main).filter(
                    item => item &&
                        (
                            normalizeString(item.url) === normalizeString(parseLocation[1])
                        )
                );
                if (searchedSubSub.length > 0) {
                    if (Object.keys(allLangDataObj).length > 0) {
                        const searchedTypes = Object.values(allLangDataObj[currentLang][searchedSubSub[0].types]);
                        if (searchedTypes.length > 0) {
                            const types = searchedTypes.filter(
                                item => item && (
                                    (parseLocation[2] && item.url === normalizeString(parseLocation[2]))
                                )
                            );
                            if (types.length > 0) {
                                setSub(() => parseLocation[1]);
                                setSubSub(() => types[0].url);
                                setSubType(() => searchedSubSub[0].types);
                            }
                        }
                    } else {
                        setComponent(() =>
                            <Error
                                lang={currentLang}
                                langs={langs}
                                datas={allLangDataObj[currentLang]}
                                menu={Object.values(allLangDataObj[currentLang].main)}
                            />
                        );
                    }
                }
            }
        }
    }, [parseLocation, currentLang, allLangDataObj, langs]);
    /***
    /* Конец определения передан ли sub в sub
    /***/

    React.useEffect(() => {
        if (!loading && currentLang) {
            if (sub && subSub) {
                !loading && Object.keys(allLangDataObj).length > 0 &&
                setComponent(() => <SubSubTemplate
                    datas={allLangDataObj[currentLang]}
                    lang={currentLang}
                    subType={subType}
                    subSub={subSub}
                    sub={sub}
                    menu={Object.values(allLangDataObj[currentLang].main)}
                    langs={langs}
                />);
            } else if (sub && !subSub) {
                !loading && Object.keys(allLangDataObj).length > 0 &&
                setComponent(() => <SubTemplate
                    datas={allLangDataObj[currentLang]}
                    lang={currentLang}
                    subType={subType}
                    sub={sub}
                    menu={Object.values(allLangDataObj[currentLang].main)}
                    langs={langs}
                />);
            } else if (!sub && !subSub) {
                !loading && Object.keys(allLangDataObj).length > 0 &&
                setComponent(() => <HomeTemplate
                    datas={allLangDataObj[currentLang]}
                    lang={currentLang}
                    subType={'types'}
                    menu={Object.values(allLangDataObj[currentLang].main)}
                    langs={langs}
                />);
            }
        }

    }, [sub, subSub, subType, langs, parseLocation, loading, allLangDataObj, currentLang]);

    if (loading || loadingMain) {
        return (
            <Loader />
        );
    }

    return (
        <div>
            {component}
        </div>
    );
};

export default App;