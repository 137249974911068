import React from 'react';
import classes from './accordion.module.sass';
import Button from "../button/button";
import T from "../../components/t/t";
import CustomImg from "../customImg/customImg";

const accordionVariant = {
    banner: 'banner',
}

const AccordionItem = ({variant, title, children, datas}) => {
    const [active, setActive] = React.useState(false);
    const accordionRef  =  React.useRef(null);
    const accordionContentRef  =  React.useRef(null);

    const toggleActive = () => {
        setActive(() => !active);
    }

    React.useEffect(() => {
        if(active){
            accordionContentRef.current.style.height = accordionContentRef.current.scrollHeight + 'px'
            accordionContentRef.current.classList.add(classes.active)
        } else {
            accordionContentRef.current.style.height = null
            accordionContentRef.current.classList.remove(classes.active)
        }
    }, [active]);

    switch (variant) {
        case accordionVariant.banner:
            return (
                <div className={`${classes.accordionItem} ${classes.banner}`}>
                    <div
                        ref={accordionRef}
                        className={`${classes.accordionItemHeader} ${active? classes.active : ''}`}
                        onClick={toggleActive}
                    >
                        <Button variant={'borderWithIconBTN'}><T datas={datas}>Полное описание</T></Button>
                    </div>
                    <div ref={accordionContentRef} className={`${classes.accordionItemBody}`}>
                        {children}
                    </div>
                </div>
            )
        default:
            return (
                <div className={classes.accordionItem}>
                    <div
                        ref={accordionRef}
                        className={`${classes.accordionItemHeader} ${active? classes.active : ''}`}
                        onClick={toggleActive}
                    >
                        {title}
                        <CustomImg src={process.env.PUBLIC_URL + '/icon/chevron.svg'} alt={title}/>
                    </div>
                    <div ref={accordionContentRef} className={`${classes.accordionItemBody}`}>
                        <div className={classes.accordionItemContent}>{children}</div>
                    </div>
                </div>
            );
    }

};

export default AccordionItem;