import React from 'react';
import Space from "../ui/space/space";
import Top from "../chanks/top";
import Footer from "../chanks/footer";
import Seo from "../components/seo/seo";

const ContactPage = ({page, lang, langs, menu,  datas, subType}) => {

    return (
        <>
            <Seo page={page}/>
            <Top menu={menu} langs={langs} lang={lang} datas={datas} subType={subType}/>
            <Space space={5}/>
            <Footer lang={lang} menu={menu} datas={datas}/>
        </>
    );
};

export default ContactPage;