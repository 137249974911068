import App from "./App";
import Error from "./Error";

export const public_routes = [
    {
        path: '/:lang?/:sub?/:subSub?',
        element: <App />
    },
    {
        path: "*",
        element: <Error
            datas={JSON.parse(localStorage.getItem('datas'))}
            lang={localStorage.getItem('defLang') ? localStorage.getItem('defLang') : localStorage.getItem('lang')}
            langs={JSON.parse(localStorage.getItem('langs'))}
            menu={JSON.parse(localStorage.getItem('menu'))}
        />
    }
];