import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import './reset.sass';
import {router} from './router';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
      <RouterProvider
          router={router}
          future={{ v7_startTransition: true }}
          basename={'/'}
      />
);