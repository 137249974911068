import React from 'react';
import CardsBlock from "../../ui/card/cardsBlock";
import Card from "../../ui/card/card";
import Button from "../../ui/button/button";
import Container from "../../ui/container/container";
import Columns from "../../ui/columns/columns";
import Space from "../../ui/space/space";
import T from "../t/t";

const PartnersComponent = ({data, description, datas, subUrl}) => {
    return (
        <Container>
            <Columns
                title={<T datas={datas}>Partners</T>}
                description={description}
                cta={
                    <>
                        <Space space={2}/>
                        <Button variant={'borderWithIcon'} rotateIcon={-90} to={'/' + subUrl}><T datas={datas}>Become a partner</T></Button>
                        <Space space={2}/>
                    </>
                }
            >
                <CardsBlock>
                    {
                        data.map(item => {
                            return (
                                <Card to={item.url} key={item.id} variant={'partners'} img={process.env.REACT_APP_STATIC_URL + '/' + item.img} title={item.title} />
                            )
                        })
                    }
                </CardsBlock>
            </Columns>
        </Container>
    );
};

export default PartnersComponent;