import React from 'react';
import Space from "../ui/space/space";
import Top from "../chanks/top";
import Footer from "../chanks/footer";
import TextComponent from "../components/text/text.component";


const TextPage = ({lang, langs, menu,  datas, subType, sub}) => {

    return (
        <>
            <Top lang={lang} langs={langs} menu={menu} datas={datas} subType={subType}/>
            <Space space={8}/>
            <TextComponent datas={datas} subType={subType} sub={sub} />
            <Space space={8}/>
            <Footer lang={lang} menu={menu} datas={datas}/>
        </>
    );
};

export default TextPage;