import React from 'react';
import Container from "../../ui/container/container";
import ColumnsSmall from "../../ui/columns/columnsSmall";
import Space from "../../ui/space/space";
import classes from './trailers.module.sass';
import Centered from "../../ui/centered/centered";
import Back from "../../ui/back/back";
import Slider from "react-slick";
import List from "../../ui/list/list";
import Button from "../../ui/button/button";
import Card from "../../ui/card/card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import parse from "html-react-parser";
import CustomImg from "../../ui/customImg/customImg";
import {useLocation} from "react-router-dom";
import T from "../t/t";
import {useDevice} from "../../hooks/useDevice";
import SiteMethods from "../../methods/site.methods";
import CustomLink from "../../ui/customLink/customLink";
import Popup from "../../ui/popup/popup";

const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }
    ]
};

const TrailerOneComponent = ({datas, sub, subUrl}) => {
    const location = useLocation();

    const [cat, setCat] = React.useState({});

    const [device, current] = useDevice();

    const [page, setPage] = React.useState({});
    const [popup, setPopup] = React.useState(false);
    const [img, setImg] = React.useState('');
    const [alt, setAlt] = React.useState('');

    React.useEffect(() => {
        const page = Object.values(datas[sub]).filter(item => item.url === location.pathname.split('/').pop())[0]
        setPage(() => page)

        SiteMethods.getCategories(localStorage.getItem('lang') || localStorage.getItem('defLang')).then(res => {
            if (res.result === 'ok') {
                console.log(res.results)
                setCat(() => res.results)
            }

        })
    }, [datas, location.pathname, sub]);

    const openPopup = (alt, src) => {
        setPopup(true)
        setImg(() => src)
        setAlt(() => alt)
    }

    const closePopup = (e) => {
        e.stopPropagation();
        setPopup(() => false)
        setImg(() => '')
        setAlt(() => '')
    }

    return (
        <Container>
            <ColumnsSmall>
                <Back to={'/' + location.pathname.split('/').at(-2)} title={<T datas={datas}>Back to all trailers</T>}/>
                <h1 style={{textTransform: 'uppercase'}}>{page.topic}<br/><span style={{textTransform: 'uppercase'}}>{page.title}</span></h1>

                <Centered>
                    <p className={classes.title}><strong>
                        {
                            cat[page.category_id]?.topic
                        }
                    </strong></p>
                </Centered>
                <div className={classes.prices}>
                    <p><span>{page.price_1hour} <T datas={datas}>eur</T></span> / <T  datas={datas}>Hour</T></p>
                    <p><span>{page.price_day} <T datas={datas}>eur</T></span> / <T  datas={datas}>Day</T></p>
                    <p><span>{page.price_day * 3} <T datas={datas}>eur</T></span> / 3 <T  datas={datas}>Day</T></p>
                    <p><span>{page.price_week} <T datas={datas}>eur</T></span> / <T  datas={datas}>Week</T></p>
                </div>
                <div className={classes.slider}>
                    <Slider {...settings}>
                        {
                            page.images && page.images.map((image) =>
                                image.img.length > 0 &&
                                    <CustomImg
                                        width={360}
                                        height={360}
                                        key={image.id}
                                        src={process.env.REACT_APP_STATIC_URL + '/' + `${image.img.split('.')[0] + '_320x240' + '.' + image.img.split('.').at(-1)}`}
                                        alt={page.topic}
                                        onClick={() => openPopup(image.topic, process.env.REACT_APP_STATIC_URL + '/' + `${image.img.split('.')[0] + '_1024x768' + '.' + image.img.split('.').at(-1)}`)}
                                    />
                            )
                        }
                    </Slider>
                </div>
                <div className={classes.list}>
                    <h4><T datas={datas}>Tehniska informacija</T></h4>
                    <List items={
                        [
                            {id: 1, title: <T datas={datas}>Load capacity</T>, data: `${page.brutto - page.netto} ` + 'kg'},
                            {id: 2, title: <T datas={datas}>Cargo space dimensions</T>, data: `${page.sizes}`},
                            {id: 3, title: <T datas={datas}>Connection</T>, data: `${page.pins}`},
                            {id: 4, title: <T datas={datas}>Категория прав не ниже</T>, data: `${page.minimum_license}`},
                        ]
                    }/>
                    <h4><T datas={datas}>Apraksts</T></h4>
                    {
                        page.subtext && parse(page.subtext)
                    }
                    {
                        current && current === 'ios'
                        ?
                            <Button to={datas.config.apple_update_link} target={'_blank'} variant={'full'}><T datas={datas}>Rezervet</T></Button>
                        :
                            <Button to={datas.config.android_update_link} target={'_blank'} variant={'full'}><T datas={datas}>Rezervet</T></Button>

                    }
                </div>
                <Space space={4}/>
                <hr/>
                <Space space={4}/>
                <h2 style={{textAlign: 'center'}}><T datas={datas}>Related Trailers</T></h2>
                <Space space={2}/>
            </ColumnsSmall>
            <div className={classes.cards}>
                {
                    Object.values(datas[sub]).filter(a => a.minimum_license === page.minimum_license && a.title !== page.title).slice(0, 4).map((trailer) => {
                        return (
                            <Card
                                key={trailer.id}
                                img={process.env.REACT_APP_STATIC_URL + '/' + `${trailer.img.split('.')[0] + '_320x240' + '.' + trailer.img.split('.').at(-1)}`}
                                to={`/${subUrl}/${trailer.url.toLowerCase()}`}
                                variant={'small'} title={trailer.title}
                            />
                        )
                    })
                }
            </div>
            <Space space={8}/>
            <Popup alt={alt} src={img} active={popup} onClose={closePopup} />
        </Container>
    );
};

export default TrailerOneComponent;