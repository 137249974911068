import React from 'react';
import Container from "../../ui/container/container";
import Columns from "../../ui/columns/columns";
import Space from "../../ui/space/space";
import Accordion from "../../ui/accordion/accordion";
import AccordionItem from "../../ui/accordion/accordionItem";
import Contacts from "../../ui/contacts/contacts";
import parse from 'html-react-parser';
import T from "../t/t";


export const contacts = [
    {id: 1, icon: process.env.PUBLIC_URL + '/icon/email.svg', text: 'info@wheelie.lv', type: 'mailto'},
    {id: 2, icon: process.env.PUBLIC_URL + '/icon/phone.svg', text: '+37120023677', type: 'tel'}
]
const FaqComponent = ({data, datas}) => {
    return (
        <Container>
            <Columns
                title={<T datas={datas}>FAQ</T>}
                description={<T datas={datas}>Busim priecigi atblidet uz jusu jautajumiem</T>}
                cta={
                    <>
                        <Space space={2}/>
                        <Contacts
                            data={contacts}
                        />
                        <Space space={1}/>
                    </>
                }
            >
                <Accordion>
                    {
                        data.map(item =>
                            <AccordionItem key={item.id} title={item.topic}>{item.text && parse(item.text)}</AccordionItem>
                        )
                    }
                </Accordion>
            </Columns>
        </Container>
    );
};

export default FaqComponent;