import React from 'react';
import NewsOnePage from "../pages/newsOne.page";
import TrailerOnePage from "../pages/trailerOne.page";

const SubSubTemplate = React.memo(({datas, lang, subType, menu, langs, sub}) => {

    const [page, setPage] = React.useState({});

    React.useEffect(() => {
        if (subType && menu) {
            setPage(() => menu.filter((item) => item.types === subType)[0]);
        }
    }, [menu, subType]);

    return (
        <>
            {
                subType === 'news' && <NewsOnePage subUrl={sub} page={page} datas={datas} sub={subType} langs={langs} lang={lang} menu={menu} />
            }
            {
                subType === 'types' && <TrailerOnePage subUrl={sub} page={page} datas={datas} sub={subType} langs={langs} lang={lang} menu={menu} />
            }
        </>
    );
});

export default SubSubTemplate;