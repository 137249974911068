import React from 'react';
import classes from  './button.module.sass';
import CustomLink from "../customLink/customLink";
import CustomImg from "../customImg/customImg";

export const ButtonVariants = {
    borderLink: 'borderLink',
    borderWithIcon:  'borderWithIcon',
    fillWithIcon:  'fillWithIcon',
    fillWithOutIcon:  'fillWithOutIcon',
    borderWithOutIcon: 'borderWithOutIcon',
    borderButton: 'borderButton',
    borderWithIconBTN: 'borderWithIconBTN',
    full: 'full'
}

const Button = ({disabled, variant, to, rotateIcon, children, onClick, target, alt}) => {
    switch(variant)  {
        case ButtonVariants.borderLink:
            return (
                <CustomLink className={`${classes.button}`} to={to} title={alt}>{children}</CustomLink>
            );
        case ButtonVariants.borderWithIcon:
            return (
                <CustomLink onClick={onClick} className={`${classes.button} ${classes.icon}`} to={to} title={alt}>
                    {children}
                    <CustomImg width={24} height={24} style={{transform: `rotate(${rotateIcon}deg)`}} src={process.env.PUBLIC_URL + '/icon/down.svg'} alt={'wheelie down icon arrow'}/>
                </CustomLink>
            );
        case ButtonVariants.borderWithIconBTN:
            return (
                <button onClick={onClick} className={`${classes.button} ${classes.icon}`}>
                    {children}
                    <CustomImg width={24} height={24} style={{transform: `rotate(${rotateIcon}deg)`}} src={process.env.PUBLIC_URL + '/icon/down.svg'} alt={'wheelie down icon arrow'}/>
                </button>
            );
        case ButtonVariants.fillWithOutIcon:
            return (
                <button onClick={onClick} disabled={disabled}>
                    {children}
                </button>
            );
        case ButtonVariants.borderWithOutIcon:
            return (
                <button  className={`${classes.button} ${classes.withoutIcon}`} onClick={onClick} disabled={disabled}>
                    {children}
                </button>
            );
        case ButtonVariants.borderButton:
            return (
                <button  className={`${classes.button} ${classes.borderButton}`} onClick={onClick} disabled={disabled}>
                    {children}
                </button>
            );
        case ButtonVariants.full:
            return (
                <CustomLink to={to} target={target} disabled={disabled} className={classes.full} title={alt}>
                    {children}
                    <CustomImg width={24} height={24} src={process.env.PUBLIC_URL + '/icon/darkArrow.svg'}
                               alt={'wheelie down icon arrow'}/>
                </CustomLink>
            );
        default:
            return (
                <button onClick={onClick} disabled={disabled}>
                    {children}
                    <CustomImg width={24} height={24} src={process.env.PUBLIC_URL + '/icon/darkArrow.svg'} alt={'wheelie down icon arrow'}/>
                </button>
            );
    }
};

export default Button;