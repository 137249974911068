import React from 'react';
import classes from './list.module.sass';

const List = ({items}) => {
    return (
        <div className={classes.list}>
            {
                items.map(item => {
                    return (
                        <div key={item.id} className={classes.item}>
                            <p>{item.title}</p>
                            <p>{item.data}</p>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default List;