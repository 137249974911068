import React from 'react';
import classes from './cookie.module.sass';
import Button from "../button/button";
import T from "../../components/t/t";

const Cookie = ({title, children, onAlloved, onDenied, onLink, datas}) => {
    return (
        <div className={classes.cookie}>
            <div className={classes.text}>
                <h4>{title}</h4>
                {children}
            </div>
            <div className={classes.buttons}>
                <div className={classes.button}>
                    <Button onClick={onAlloved} variant={'fillWithOutIcon'}><T datas={datas}>Allow All</T></Button>
                    <Button onClick={onDenied} variant={'borderWithOutIcon'}><T datas={datas}>Deny</T></Button>
                </div>
                <Button onClick={onLink} variant={'borderButton'}><T datas={datas}>Manage Preferences</T></Button>
            </div>
        </div>
    );
};

export default Cookie;