import React from'react';

export const useScroller = () => {
    const [pos, setPos] = React.useState(0);

    React.useEffect(() => {
        window.addEventListener('scroll', ()  =>  setPos(window.scrollY));
    }, []);

    return {
        pos
    }
}