import React from 'react';
import Space from "../../ui/space/space";
import CardsBlock from "../../ui/card/cardsBlock";
import Card from "../../ui/card/card";
import Centered from "../../ui/centered/centered";
import Container from "../../ui/container/container";
import moment from "moment";
import T from "../t/t";
import Button from "../../ui/button/button";

const LatestNewsComponent = ({data, subUrl, datas}) => {

    return (
        <Container>
            <h2><T datas={datas}>Latest News</T></h2>
            <Space space={2}/>
            <CardsBlock>
                {
                    data.length > 3
                        ? data.slice(0, 3).sort((a, b) => a?.add_utime - b?.add_utime).map(item => {
                            return (
                                <Card
                                    key={item.id}
                                    to={'/' + subUrl + '/' + item.url}
                                    variant={'news'}
                                    textBtn={<T datas={datas}>More</T>}
                                    date={moment(Date(item.add_utime)).format('DD MMMM, YYYY')}
                                    img={process.env.REACT_APP_STATIC_URL + item.img}
                                    title={item.title}
                                />
                            )
                        })
                        :
                        data.sort((a, b) => a?.add_utime - b?.add_utime).map(item => {
                            return (
                                <Card
                                    key={item.id}
                                    to={'/' + subUrl + '/' + item.url}
                                    variant={'news'}
                                    textBtn={<T datas={datas}>More</T>}
                                    date={moment(Date(item.add_utime)).format('DD MMMM, YYYY')}
                                    img={process.env.REACT_APP_STATIC_URL + item.img}
                                    title={item.title}
                                />
                            )
                        })
                }
            </CardsBlock>
            <Space space={4}/>
            <Centered>
                <Button variant={'borderWithIcon'} rotateIcon={-90} to={'/' + subUrl}><T datas={datas}>Read All News</T></Button>
            </Centered>
        </Container>
    );
};

export default LatestNewsComponent;