import React from 'react';
import classes from './share.module.sass';
import {useLocation} from "react-router-dom";
import CustomLink from "../customLink/customLink";
import CustomImg from "../customImg/customImg";

const Share = ({items}) => {
    const location = useLocation()

    return (
        <div className={classes.share}>
            {
                items.map(item =>
                    <CustomLink width={48} height={48} key={item.id} to={item.url + 'https://wheelie.lv' + location.pathname} target="_blank" rel="noopener noreferrer">
                        <CustomImg width={48} height={48} src={process.env.REACT_APP_STATIC_URL + item.img} alt={item.topic} />
                    </CustomLink>
                )
            }
        </div>
    );
};

export default Share;