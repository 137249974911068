import React from 'react';
import SiteMethods from "../../methods/site.methods";

const T = ({datas, children}) => {
    const [translate, setTranslate] = React.useState('');

    React.useEffect(() => {
        if (localStorage.getItem('lang') || localStorage.getItem('defLang')){
            if (children){
                if (datas && Object.keys(datas.staticData).length > 0){
                    if (datas.staticData[children]) {
                        setTranslate(() => datas.staticData[children])
                    } else {setTranslate(children)
                        const formData = new FormData();
                        formData.append('set', children)
                        formData.append('text', children)
                        formData.append('type', 'app')
                        setTimeout(() => {
                            SiteMethods.addStatic(formData).then()
                        }, 100)
                    }
                }
            }
        }
    }, [children, datas])

    return (
        translate
    );
};

export default T;