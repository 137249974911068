import React from 'react';
import Space from "../ui/space/space";
import Top from "../chanks/top";
import Footer from "../chanks/footer";
import ErrorComponent from "../components/error/error.component";


const ErrorPage = ({datas, lang, langs, menu}) => {

    return (
        <>
            <Top lang={lang} menu={menu} langs={langs} datas={datas}/>
            <Space space={8}/>
            <ErrorComponent datas={datas} />
            <Footer menu={menu} datas={datas}/>
        </>
    )
};

export default ErrorPage;