import React from 'react';
import classes from './columns.module.sass';

const Columns = ({title, description, cta, children}) => {

    return (
        <div className={classes.columns}>
            <div className={classes.column__left}>
                {
                    title && <h2>{title}</h2>
                }
                {
                    description && <p>{description}</p>
                }
                {cta}
            </div>
            <div className={classes.column__right}>
                {children}
            </div>
        </div>
    );
};

export default Columns;