import React from 'react';
import classes from './contacts.module.sass';
import {Link} from "react-router-dom";

const Contacts = ({right, data}) => {
    return (
        <div className={classes.contacts}>
            {
                data.map(item => {
                    return (
                        <Link
                            key={item.id}
                            to={item.type === 'location'
                                ? `https://www.google.com/maps/search/${item.text}`
                                : `${item.type}:${item.text}`
                            }
                            className={right ? classes.item + ' ' + classes.right : classes.item}
                            alt={'wheelie contacts'}
                            target={item.type === 'location' ? '_blank' : '_self'}
                        >
                            <amp-img width={24} height={24} src={item.icon}>
                                <img loading={'lazy'} width={24} height={24} src={item.icon} alt="contacts icon"/>
                            </amp-img>
                            <span>{item.type === 'tel' ? item.text.slice(0, 4) + ' ' + item.text.slice(4) : item.text }</span>
                        </Link>
                    );
                })
            }
        </div>
    );
};

export default Contacts;