import React from 'react';
import Container from "../../ui/container/container";
import {GoogleMapsProvider} from "@ubilabs/google-maps-react-hooks";
import Polygon from "../../ui/polygon/polygon";
import Markers from "../../ui/marker/markers";
import T from "../t/t";

const GMap = ({datas, mapOptions, lang}) => {
    const [mapContainer, setMapContainer] = React.useState(null);

    const mapRef = React.useCallback(node => {
        node && setMapContainer(node);
    }, []);

    return (
        <Container>
            <h2><T datas={datas}>Piekabju atrašanās vietas</T></h2>
            <GoogleMapsProvider
                googleMapsAPIKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                mapOptions={mapOptions}
                yesIWantToUseGoogleMapApiInternals
                mapContainer={mapContainer}
                language={localStorage.getItem('lang') || 'lv'}
            >
                <div ref={mapRef} className={'iframe'}/>
                <Polygon datas={datas} />
                <Markers
                    mr={mapContainer}
                    trailers={datas.trailers}
                    types={datas.types}
                />
            </GoogleMapsProvider>
        </Container>
    );
};

export default GMap;