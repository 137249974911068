import React from 'react';
import classes from './popup.module.sass';
import CustomImg from "../customImg/customImg";

const Popup = ({active, onClose, src, alt}) => {

    return (
        <div className={active ? `${classes.popup} ${classes.popup_active}` : classes.popup} onClick={(e) => onClose(e)}>
            <div className={classes.content_popup}>
                <CustomImg
                    className={classes.img}
                    src={src}
                    width='1024'
                    height='768'
                    alt={alt}
                />
            </div>
        </div>
    );
};

export default Popup;