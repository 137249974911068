import React from 'react';
import FaqComponent from "../components/faq/faq.component";

const Faq = ({data, datas}) => {

    return (
        <FaqComponent datas={datas} data={Object.values(data)} />
    );
};

export default Faq;