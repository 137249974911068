import React from 'react';
import classes from './input.module.sass';

const Input = ({htmlFor, label, type, value, onChange}) => {
    return (
        <div className={classes.input}>
            <label htmlFor={htmlFor}>{label}</label>
            <input id={htmlFor} type={type} value={value} onChange={onChange}/>
        </div>
    );
};

export default Input;