import React from 'react';
import Centered from "../../../ui/centered/centered";
import Space from "../../../ui/space/space";
import classes from './homeBanner.module.sass';
import Container from "../../../ui/container/container";
import {useDevice} from "../../../hooks/useDevice";
import Accordion from "../../../ui/accordion/accordion";
import AccordionItem from "../../../ui/accordion/accordionItem";
import parse from 'html-react-parser';
import CustomLink from "../../../ui/customLink/customLink";
import CustomImg from "../../../ui/customImg/customImg";
import T from "../../t/t";

const HomeBannerComponent = ({datas, topic, subtext, text, qr, banner}) => {
    const [device, current] = useDevice();

    return (
        <section className={classes.homeBanner}>
            <Space space={2}/>
            <Container>
                <Centered>
                    <h1><span>Wheelie.lv</span> - {topic}</h1>
                    {
                        subtext && device && device === 'desktop' ?
                            parse(subtext)
                            :
                            <p><T datas={datas}>Скачайте приложение по кнопке ниже</T></p>
                    }
                    {
                        device && device === 'desktop'
                            ?
                                <CustomImg width={200} height={200} src={process.env.REACT_APP_STATIC_URL +  qr} alt={`Wheelie.lv - ${topic}`}/>
                            :
                            current && current === 'ios'
                                ?
                                <CustomLink to={datas.config.apple_update_link} target={"_blank"}>
                                    <CustomImg src={process.env.PUBLIC_URL + '/icon/appStore.svg'} alt={`Wheelie.lv - App for ios`}/>
                                </CustomLink>
                                :
                                <CustomLink to={datas.config.android_update_link} target={"_blank"}>
                                    <CustomImg src={process.env.PUBLIC_URL + '/icon/googlePlay.svg'} alt={`Wheelie.lv - App for android`}/>
                                </CustomLink>
                    }
                    <Space space={1}/>
                    <Accordion>
                        <AccordionItem datas={datas} variant={'banner'}>
                            {text && parse(text)}
                        </AccordionItem>
                    </Accordion>
                    <Space space={3}/>
                    <CustomImg width={846} height={719} src={process.env.REACT_APP_STATIC_URL + banner} alt={`Wheelie.lv - phone icons`}/>
                </Centered>
            </Container>
            <Space space={2}/>
        </section>

    );
};

export default HomeBannerComponent;