import React from 'react';
import HeaderComponent from "../components/header/header.component";


const Top = ({subUrl, langs,  lang, menu, datas, subType}) => {
    return (
        <HeaderComponent datas={datas} subUrl={subUrl} menu={menu} languages={langs} currentLang={lang} subType={subType}/>
    );
};

export default Top;