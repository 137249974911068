import React from 'react';
import Space from "../../ui/space/space";
import CardsBlock from "../../ui/card/cardsBlock";
import Card from "../../ui/card/card";
import Container from "../../ui/container/container";
import moment from "moment/moment";
import T from "../t/t";
import classes from "../partners/partnersSlider.module.sass";
import parse from "html-react-parser";

const NewsComponent = ({page, data, datas}) => {

    console.log(data)
    return (
        <Container>
            <Space space={2}/>
            <div className={classes.partnetSlidet__title}>
                <h1>{page.topic}</h1>
                <div className={classes.partnetSlidet__subtitle}>
                    {page.text && parse(page.text)}
                </div>
            </div>
            <Space space={2}/>
            <CardsBlock>
                {
                    data && data.sort((a, b) => a.add_utime - b.add_utime).map(item => {
                        return (
                            <Card
                                to={'/' + page.url + '/' + item.url}
                                key={item.id}
                                variant={'news'}
                                alt={item.title}
                                textBtn={<T datas={datas}>More</T>}
                                date={moment(Date(item.add_utime)).format('DD MMMM, YYYY')}
                                img={process.env.REACT_APP_STATIC_URL + item.img}
                                title={item.title}
                            />
                        )
                    })
                }
            </CardsBlock>
        </Container>
);
};

export default NewsComponent;