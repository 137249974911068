import React from 'react';
import {useGoogleMap} from "@ubilabs/google-maps-react-hooks";

const Polygon = ({datas}) => {
    const map = useGoogleMap();

    console.log(datas);

    const drawPolygon = React.useCallback(() => {
        datas.parking.length > 0 &&
        datas.parking.forEach(item => {
            const polygon = new window.google.maps.Polygon({
                paths: item.parking_path,
                strokeColor: "#0769bb",
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: "#008cff",
                fillOpacity: 0.35,
            });
            polygon.setMap(map);
        })
    }, [map, datas])

    React.useEffect(() => {
        if (!map) {
            return () => {};
        }
        drawPolygon()
    }, [drawPolygon, map])

};

export default Polygon;
