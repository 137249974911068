import React from 'react';
import {useGoogleMap} from "@ubilabs/google-maps-react-hooks";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {useNavigate} from "react-router-dom";
import TRAILER_ICON from './trailerMarker.svg';

const Markers = ({mr, trailers, types}) => {
    const map = useGoogleMap();
    const navigate = useNavigate();
    const [drawingMarker, setDrawingMarker] = React.useState(false);

    React.useEffect(() => {
        if (!map) {
            return () => {};
        }
        let markers = []
        const bounds = new window.google.maps.LatLngBounds();
        if (trailers && !drawingMarker) {
            let markerOptions;
            Object.entries(trailers).forEach(item => {
                if (types[item[1].types_id]) {
                    markerOptions = {
                        key: item[0],
                        map,
                        position: item[1].geo,
                        title: item[1].name,
                        icon: TRAILER_ICON,
                        zIndex: 1,
                        width: 30,
                        height: 30,
                        className: 'marker',
                        label: {
                            text: `${types[item[1].types_id].title} - ${item[1].numur}`,
                            icon: process.env.REACT_APP_STATIC_URL + types[item[1].types_id].icon,
                            className: 'marker-label',
                        }
                    };
                }
                bounds.extend(new window.google.maps.LatLng(item[1].geo));
                const marker = new window.google.maps.Marker(markerOptions);
                markers.push(marker);
                setDrawingMarker(true);
                return marker;
            })
            new MarkerClusterer({map, markers});
            // map.zoom = 8
            map.fitBounds(bounds)
        }
    }, [map, trailers, drawingMarker, types, navigate, mr])

};

export default Markers;
