import React from 'react';
import Space from "../ui/space/space";
import Top from "../chanks/top";
import Footer from "../chanks/footer";
import Seo from "../components/seo/seo";
import TrailerOneComponent from "../components/trailers/trailerOne.component";


const TrailerOnePage = ({subUrl, page, datas, sub, langs, lang, menu}) => {

    return (
        <>
            <Seo page={page}/>
            <Top lang={lang} menu={menu} langs={langs} subUrl={subUrl} datas={datas} subType={sub}/>
            <Space space={8} />
            <TrailerOneComponent sub={sub} datas={datas} subUrl={subUrl}/>
            <Space space={8} />
            <Footer lang={lang} menu={menu} datas={datas}/>
        </>
    );
};

export default TrailerOnePage;