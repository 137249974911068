import React from 'react';
import {useClickOutComponent} from "../../hooks/useClickOutComponent";
import {useScroller} from "../../hooks/useScroller";
import Header from "../../ui/header/header";
import Container from "../../ui/container/container";
import CustomLink from "../../ui/customLink/customLink";
import Menu from "../../ui/menu/menu";
import CustomImg from "../../ui/customImg/customImg";
import Translater from "../../ui/translater/translater";
import Space from "../../ui/space/space";
import Contacts from "../../ui/contacts/contacts";
import Cookie from "../../ui/cookie/cookie";
import CookieSettings from "../../ui/cookie/cookieSettings";
import Checkbox from "../../ui/checkbox/checkbox";
import classes from './header.module.sass';
import {contacts} from "../faq/faq.component";
import T from "../t/t";

const HeaderComponent = ({subUrl, languages, currentLang, menu, datas, subType}) => {
    const cookieSettings = [
        {id: 1, required: true,  check: true, name: <T datas={datas}>Strictly required cookies</T>, description: <T datas={datas}>These cookies are crucial for site function and cannot be blocked. Blocking them might affect how our website works</T>},
        {id: 2, required: false,  check: false, name: <T datas={datas}>Analytics</T>, description: <T datas={datas}>These cookies are crucial for site function and cannot be blocked. Blocking them might affect how our website works</T>},
        {id: 3, required: false,  check: true, name: <T datas={datas}>Functionality Cookies</T>, description: <T datas={datas}>These cookies are crucial for site function and cannot be blocked. Blocking them might affect how our website works</T>},
    ]

    const [active, setActive] = React.useState(false);
    const [activeMob, setActiveMob] = React.useState(false);
    const [activeMenu, setActiveMenu] = React.useState(false);
    const translaterRef = React.useRef();
    const translaterMobileRef = React.useRef();

    const [cookie] = React.useState(localStorage.getItem('cookie-allowed'));
    const [cookieBanner, setCookieBanner] = React.useState(true);
    const [cSetting, setCSetting] = React.useState(false);
    const [cookieData] = React.useState(cookieSettings);

    React.useEffect(()  => {
        if (cookie) {
            setCookieBanner(() => false)
        }
    }, [cookie])

    const allowedCookies  = ()  => {
        localStorage.setItem('cookie-allowed', String(Date.now()));
        setCookieBanner(() =>  false);
    }

    const deniedCookies  = ()  => {
        setCookieBanner(() =>  false);
    }

    const settingCookies = () => {
        setCSetting(()  =>  true);
    }

    const {pos} = useScroller();

    useClickOutComponent(translaterRef, setActive);
    useClickOutComponent(translaterMobileRef, setActiveMob);

    const toggleLangs = () => {
        setActive(() => !active);
    }

    const toggleLangsMob = () => {
        setActiveMob(() => !activeMob);
    }

    const toggleMenu = () => {
        setActiveMenu(() => !activeMenu)
    }

    const allowedCookiesAll =  ()  =>  {
        localStorage.setItem('cookie-allowed', String(Date.now()));
        setCookieBanner(() =>  false);
        setCSetting(() => false);
    }

    const deniedCookiesAll = () => {
        setCookieBanner(() => false);
        setCSetting(() => false);
    }

    const toggleCheckBox = (e) => {
        cookieData.find(item => item.id === Number(e.target.id))
    }

    const switchLangs = (lang) => {
        if (lang !== 'lv') {
            localStorage.setItem('lang', lang);
        } else {
            localStorage.removeItem('lang');
        }
        window.location.reload();
    }

    return (
        <>
            <Header className={Number(pos) > 10 ? classes.fixed + ' ' + classes.bg + ' ' + classes.shadow : classes.fixed}>
                <Container>
                    <div className={classes.menuContent}>
                        <CustomLink to={"/"} alt={"Home"} width={128} height={36}>
                            <CustomImg loading={'lazy'} width={128} height={36} src={process.env.PUBLIC_URL + '/icon/logo.svg'} alt={`Logo`}/>
                        </CustomLink>
                        <Menu
                            menu={menu}
                            active={activeMenu}
                            onClick={toggleMenu}
                            onClose={toggleMenu}
                            currentLang={currentLang}
                            subUrl={subUrl}
                            datas={datas}
                            translate={
                                <Translater
                                    mobile={true}
                                    ref={translaterMobileRef}
                                    onClick={toggleLangsMob}
                                    active={activeMob}
                                    currentLang={currentLang}
                                    languages={languages}
                                    onChange={switchLangs}
                                    datas={datas}
                                    subUrl={subUrl}
                                    subType={subType}
                                />
                            }
                        >
                            <Space space={2} />
                            <p className={"menuText"}><T datas={datas}>Lejupielādēt aplikāciju</T></p>
                            <Space space={1}></Space>
                            <CustomLink to={datas.config.apple_update_link}  target={'_blank'} alt={"AppStore"} width={240} height={120}>
                                <CustomImg loading={'lazy'} width={240} height={120} src={process.env.PUBLIC_URL + '/icon/appStore.svg'} alt={"Logo"}/>
                            </CustomLink>
                            <CustomLink to={datas.config.android_update_link}  target={'_blank'} alt={"PlayMarket"} width={240} height={120}>
                                <CustomImg loading={'lazy'} width={240} height={120} src={process.env.PUBLIC_URL + '/icon/googlePlay.svg'} alt={"Logo"}/>
                            </CustomLink>
                            <Space space={3} />
                            <Contacts right={true} data={contacts} />
                        </Menu>
                        <Translater
                            ref={translaterRef}
                            onClick={toggleLangs}
                            active={active}
                            currentLang={currentLang}
                            languages={languages}
                            onChange={(e) => switchLangs(e)}
                        />
                    </div>
                </Container>
            </Header>

            {
                cookieBanner
                &&
                <>
                    <Cookie datas={datas} title={<T datas={datas}>We use cookies</T>} onAlloved={allowedCookies} onDenied={deniedCookies} onLink={settingCookies}>
                        <p>
                            <T datas={datas}>We use cookies in order to ensure our website’s functionality and collect anonymous analytical data. You can accept all cookies by clicking “Accept” or manage cookie your settings below by clicking “Manage preferences” For more information, please read our</T> <CustomLink to={'/policy/policy'}><T>Privacy Policy</T></CustomLink> <T>and</T> <CustomLink to={'/policy/policy'}><T>Cookie Policy</T></CustomLink>.
                        </p>
                    </Cookie>
                    {
                        cSetting
                        &&
                        <CookieSettings datas={datas} title={<T datas={datas}>Manage Cookies</T>} onAlloved={allowedCookiesAll} onDenied={deniedCookiesAll}>
                            {
                                cookieData.map(item =>
                                    <div key={item.id} className={classes.item}>
                                        <div>
                                            <h5>{item.name}</h5>
                                            <p>{item.description}</p>
                                        </div>
                                        <Checkbox htmlFor={item.id} onChange={toggleCheckBox} disabled={item.required} checked={item.check} />
                                    </div>
                                )
                            }
                        </CookieSettings>
                    }
                </>
            }

        </>
    );
};

export default HeaderComponent;