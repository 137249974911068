import React from 'react';
import classes from './translater.module.sass';
import CustomImg from "../customImg/customImg";
import {useNavigate} from "react-router-dom";

const Translater = React.forwardRef((
    {
        mobile,
        currentLang,
        languages,
        active,
        onClick,
        onChange,
        datas,
        subUrl,
        subType
    },
    ref
    ) => {

    const navigate = useNavigate();

    React.useEffect(() => {
        if (subUrl) {
            console.log('subUrl', subUrl);
        }

        if (subType && datas) {
            console.log('datas', datas[subType])
            console.log('datasMain', Object.values(datas.main).filter(item => item.types === subType)[0])
            console.log('subType', subType);
        }
    }, [subUrl, datas, subType]);

    return (
        <div ref={ref} className={mobile ? classes.translater + ' ' + classes.mobile : classes.translater}>
            <div className={active ? classes.currentLang + ' ' + classes.active : classes.currentLang} onClick={onClick}>
                <p>{currentLang}</p>
                <CustomImg width={24} height={24} alt={currentLang} src={process.env.PUBLIC_URL + '/icon/chevron.svg'}/>
            </div>
            <div className={active ? classes.languages + ' ' + classes.active : classes.languages}>
                {
                    languages.map(
                        lang => lang.code !== currentLang &&
                            <p onClick={() => navigate(lang.code !== 'lv' ? '/' + lang.code + '/' : '/')} key={lang.id}>{lang.code}</p>
                    )
                }
            </div>
        </div>
    );
});

export default Translater;