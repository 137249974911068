import React from 'react';
import classes from './partnersSlider.module.sass';
import Container from "../../ui/container/container";
import Slider from "react-slick";
import T from "../t/t";
import CustomImg from "../../ui/customImg/customImg";
import Columns from "../../ui/columns/columns";
import CustomLink from "../../ui/customLink/customLink";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }
    ]
};

const PartnerSliderComponent = ({datas}) => {
    const [trUrl, setTrUrl] = React.useState("");

    React.useEffect(() => {
        setTrUrl(() => Object.values(datas.main).filter(item => item.types === 'types')[0].url)
    }, [datas]);

    return (
        <div className={classes.partnersSlider}>
            <Container>
                <div className={classes.partnetSlidet__title}>
                    <h2><T datas={datas}>Why Wheelie Trailers?</T></h2>
                    <div className={classes.partnetSlidet__subtitle}>
                        <h4><T datas={datas}>With Wheelies seamless integration, not only enjoy a share of the rental revenue but also gain valuable brand exposure</T></h4>
                        <p><T datas={datas}>By providing space for our trailers at your location, you open the door for customers to conveniently rent a trailer during their visit. Customers who rent a trailer without an initial visit will be exposed to your business when they come to pick up their rental, presenting a prime opportunity to capture new leads and showcase your offerings.</T></p>
                    </div>
                </div>
            </Container>
            <div className={classes.partnetSlidet__slider}>
                <Slider {...settings}>
                    {
                        Object.values(datas['types']).sort((a, b) => a.position - b.position).map(type =>
                            <CustomLink to={'/' + trUrl + '/' + type.url}>
                                <CustomImg src={process.env.REACT_APP_STATIC_URL + `${type.img.split('.')[0] + '_320x240' + '.' + type.img.split('.').at(-1)}`}
                                 alt={type.topic + ' ' + type.title}/>
                            </CustomLink>
                        )
                    }
                </Slider>
            </div>

            <Container>
                <Columns>
                    <div className={classes.partnetSlidet__counter}>
                        <div className={classes.partnetSlidet__counterItem}>
                            <p><T datas={datas}>20</T></p>
                            <p><T datas={datas}>Wheelie locations across Latvia</T></p>
                        </div>
                        <div className={classes.partnetSlidet__counterItem}>
                            <p><T datas={datas}>2000 +</T></p>
                            <p><T datas={datas}>Rentals per year</T></p>
                        </div>
                        <div className={classes.partnetSlidet__counterItem}>
                            <p><T datas={datas}>3000 +</T></p>
                            <p><T datas={datas}>Active users in the last year</T></p>
                        </div>
                        <div className={classes.partnetSlidet__counterItem}>
                            <p><T datas={datas}>50 +</T></p>
                            <p><T datas={datas}>Wheelie trailers on the roads</T></p>
                        </div>
                    </div>
                </Columns>
            </Container>
        </div>
    );
};

export default PartnerSliderComponent;