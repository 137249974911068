import React from 'react';
import Top from "../chanks/top";
import Space from "../ui/space/space";
import Trailers from "../chanks/trailers";
import Faq from "../chanks/faq";
import Footer from "../chanks/footer";
import Seo from "../components/seo/seo";
import HomeBannerComponent from "../components/banners/home/homeBanner.component";
import LatestNewsComponent from "../components/news/latestNews.component";
import PartnersComponent from "../components/partners/partners.component";
import GMap from "../components/gMap/gMap";

const mapOptions = {
    mapId: 'e30b6ede3cd06261',
    // center: {lat: parseFloat('56.94495028955723'), lng: parseFloat('24.03021551512225')},
    center: {lat: parseFloat('0'), lng: parseFloat('0')},
    disabledDefaultUi: true,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: true,
    zoom: 12,
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {
        position: 8 // Right top
    },
};

const MainPage = ({lang, langs, menu, datas}) => {

    const [page, setPage] = React.useState({});

    React.useEffect(() => {
        setPage(() => menu.filter(item => item.types === 'home')[0]);
    }, [menu]);

    return (
        <>
            <Seo page={page}/>
            <Top langs={langs} lang={lang} menu={menu} datas={datas}/>
            <Space space={8}/>
            <HomeBannerComponent datas={datas} topic={page.title} subtext={page.subtext} text={page.text} qr={page.qrcode_img} banner={page.img} />
            <Space space={8}/>
            <Trailers datas={datas} page={page} data={datas && Object.values(datas["types"])} subUrl={menu.filter(item => item.types === 'types')[0]?.url}/>
            <Space space={8}/>
            <GMap mapOptions={mapOptions} datas={datas} lang={lang} />
            <Space space={8}/>
            <LatestNewsComponent datas={datas} data={datas && Object.values(datas["news"])} subUrl={menu.filter(item => item.types === 'news')[0]?.url}/>
            <Space space={8}/>
            <PartnersComponent datas={datas} data={datas && Object.values(datas["partners"])} description={page.partner_title} subUrl={menu.filter(item => item.types === 'partners')[0]?.url}/>
            <Space space={8}/>
            <Faq  datas={datas} data={datas && Object.values(datas["faq"])}/>
            <Space space={8}/>
            <Footer lang={lang} menu={menu} datas={datas}/>
        </>
    );
};

export default MainPage;