import React from "react";

export const useDevice = () => {
    const [device, setDevice] = React.useState('');
    const [current, seCurrent] = React.useState('');
    React.useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
        if (isMobile){
            setDevice(() => 'mobile')
            if (
                window.navigator.userAgent.split('(')[1].split(';')[0] === 'iPhone' ||
                window.navigator.userAgent.split('(')[1].split(';')[0] === 'iPad'){
                seCurrent(() => 'ios')
            } else {
                seCurrent(() => 'android')
            }
        } else {
            setDevice(() => 'desktop')
        }
    }, [])
    return [
        device, current
    ]
}