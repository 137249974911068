import React from 'react';
import c from './loader.module.sass';
import CustomImg from "../customImg/customImg";

const Loader = () => {
    return (
        <div className={c.loader}>
            <CustomImg width={100} height={100} src={process.env.PUBLIC_URL + '/icon/logo_small.svg'} alt="wheelie.lv"/>
            <h5 style={{fontSize: '2rem'}}>Wheelie</h5>
            <span className={c.ldsRing}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </span>
        </div>
    );
};

export default Loader;
