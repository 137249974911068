import React from 'react';
import Space from "../ui/space/space";
import Top from "../chanks/top";
import Footer from "../chanks/footer";
import Faq from "../chanks/faq";
import Seo from "../components/seo/seo";
import PartnerBannerComponent from "../components/banners/partner/partnerBanner.component";
import PartnerSliderComponent from "../components/partners/partnerSlider.component";
import PartnerBenefitComponent from "../components/partners/partnerBenefit.component";


const PartnersPage = ({page, langs, lang, menu, datas, subType, subUrl}) => {

    return (
        <>
            <Seo page={page}/>
            <Top langs={langs} menu={menu} lang={lang} datas={datas} subType={subType}/>
            <PartnerBannerComponent page={page} datas={datas} menu={menu}/>
            <PartnerSliderComponent datas={datas} />
            <Space space={8}/>
            <PartnerBenefitComponent datas={datas}/>
            <Space space={8}/>
            {/*<Faq lang={lang} data={datas['faq']} datas={datas}/>*/}
            <Space space={8}/>
            <Footer lang={lang} menu={menu} datas={datas}/>
        </>
    );
};

export default PartnersPage;