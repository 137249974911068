import React from 'react';
import ErrorPage from "./pages/error.page";

const Error = ({datas, lang, langs, menu}) => {

    return (
        <ErrorPage datas={datas} lang={lang} langs={langs} menu={menu} />
    );
};

export default Error;