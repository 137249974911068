import React from 'react';
import classes from './card.module.sass';
import Button from "../button/button";
import CustomImg from "../customImg/customImg";
import CustomLink from "../customLink/customLink";

export const cardVariant = {
    news: 'news',
    partners: 'partners',
    small: 'small',
    dark: 'dark',
}

const Card = ({variant, title, img, price, data, textBtn, date, to, count, children, style}) => {
    switch (variant) {
        case cardVariant.news:
            return (
                <div className={`${classes.card} ${classes.news}`}>
                    <h3>{title}</h3>
                    <CustomImg width={308} height={173} src={img} alt={title}/>
                    <div className={classes.price}>
                        <p>{date}</p>
                        <Button variant={'borderLink'} to={to}>{textBtn}</Button>
                    </div>
                </div>
            )
        case cardVariant.partners:
            return (
                <CustomLink to={to} className={`${classes.card} ${classes.partners}`} alt={title} target={'_blank'}>
                    <CustomImg width={163} height={21} src={img} alt={title}/>
                </CustomLink>
            )
        case cardVariant.small:
            return (
                <CustomLink to={to} className={`${classes.small}`}>
                    <div className={classes.img}>
                        <CustomImg  width={308} height={173} src={img} alt={title}/>
                    </div>
                    <h3>{title}</h3>
                </CustomLink>
            )
        case cardVariant.dark:
            return (
                <div style={style} className={`${classes.dark}`}>
                    <div className={classes.counter}>
                        <CustomImg src={process.env.PUBLIC_URL + '/icon/trailerIcon.svg'} alt={title}/>
                        <p>{count}</p>
                    </div>
                    <div>
                        <h3>{title}</h3>
                        <p>{children}</p>
                    </div>
                </div>
            )
        default:
            return (
                <div className={`${classes.card} ${classes.default}`}>
                    <div className={classes.img}>
                        <CustomImg width={308} height={173} src={img} alt={title}/>
                    </div>
                    <h3>{title}</h3>
                    {
                        data.map(item =>
                            <div className={classes.info} key={item.name}>
                                <p>{item.name}</p>
                                <p style={{textAlign: 'right'}}>{item.data}</p>
                            </div>
                        )
                    }
                    <div className={classes.price}>
                        <p>No <span>{price} EUR</span> / Hour</p>
                        <Button variant={'borderLink'} to={to} alt={title}>{textBtn}</Button>
                    </div>
                </div>
            );
    }

};

export default Card;