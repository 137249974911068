import React from 'react';
import classes from './columns.module.sass';

const ColumnsSmall = ({children}) => {

    return (
        <div className={classes.columnsSmall}>
            {children}
        </div>
    );
};

export default ColumnsSmall;