import React from 'react';
import classes from "./back.module.sass";
import CustomLink from "../customLink/customLink";
import CustomImg from "../customImg/customImg";

const Back = ({style, to, title}) => {
    return (
        <div style={style} className={classes.back}>
            <CustomLink className={classes.a_link} to={to} alt={'Back to'} width={200} height={48}>
                <CustomImg width={24} height={24} src={process.env.PUBLIC_URL + '/icon/down.svg'} alt={'Back to'}/>
                {title}
            </CustomLink>
        </div>
    );
};

export default Back;