import React from 'react';
import classes from './partnerBenefit.module.sass'
import Container from "../../ui/container/container";
import Card from "../../ui/card/card";
import T from "../t/t";

const PartnerBenefitComponent = ({datas}) => {
    const benefits =  [
        {id: 0, title:  '', description: ''},
        {id: 1, title:  <T datas={datas}>Flexible self-service solution</T>, description: <T datas={datas}>Wheelie targets your ideal audience at the right moment through rental integration. Achieve cost-efficiency with our low cost per thousand impressions.</T>},
        {id: 2, title:  <T datas={datas}>Effective outdoor advertising</T>, description: <T datas={datas}>Wheelie exposes your business directly to your target audience when they pick up trailers, delivering your message at the right time.</T>},
        {id: 3, title:  <T datas={datas}>No workload for you</T>, description: <T datas={datas}>Our self-service solution is organized to take work off your hands, making Wheelie an easy partner.</T>},
        {id: 4, title:  <T datas={datas}>More sales with effective marketing</T>, description: <T datas={datas}>Wheelie targets your ideal audience at the right moment through rental integration. Achieve cost-efficiency with our low cost per thousand impressions.</T>},
        {id: 5, title:  <T datas={datas}>Ongoing maintenance and service</T>, description: <T datas={datas}>Wheelie ensures well-maintained, roadworthy trailers through our maintenance programs, providing customers with a reliable service.</T>},
        {id: 6, title:  <T datas={datas}>An extra service for your customers</T>, description: <T datas={datas}>Unlock the power of providing your customers with a complimentary and adaptable transport service, fostering customer loyalty. Join us today and elevate your customer experience.</T>},
    ]

    return (
        <Container>
            <div className={classes.partnerBenefit}>
                <div className={classes.partnerBenefit__title}>
                    <h2><T datas={datas}>Your benefits as a Partner</T></h2>
                    <p><T datas={datas}>We handle the entire car trailer rental process for both partners and customers. From managing practicalities to optimizing your marketing efforts across all touchpoints – online, at pick-up, drop-off, and beyond.</T></p>
                </div>
                <div className={classes.partnerBenefit__content}>
                    {
                        benefits.map((item, index) =>
                            <Card style={index === 0 ? {opacity: 0} : {opacity:  1}} variant={'dark'} count={index} key={item.id} title={item.title}>{item.description}</Card>
                        )
                    }
                </div>
            </div>
        </Container>
    );
};

export default PartnerBenefitComponent;