import React from 'react';
import classes from './header.module.sass';

const Header = ({children, className}) => {
    return (
        <div className={classes.menu + ' ' + className}>
            {children}
        </div>
    );
};

export default Header;